import { TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLDialogElement> {
  @Value(Boolean) readonly closeOnEscapeValue: boolean;
  @Value(Boolean) readonly closeOnBackdropClickValue: boolean;
  @Value(String) readonly redirectToUrlAfterCloseValue: string;

  // TODO: implement this
  // @Value(Boolean) readonly safeToCloseValue: boolean;

  connect() {
    if (!this.closeOnEscapeValue) {
      this.element.addEventListener("close", (event) => {
        event.preventDefault();
      });
    }

    if (this.closeOnBackdropClickValue) {
      this.element.addEventListener("click", (event) => {
        if (event.target === event.currentTarget) {
          (event.currentTarget as HTMLDialogElement)?.close();
        }
      });
    }
  }

  openModal() {
    this.element.showModal();
  }

  closeModal() {
    if (this.redirectToUrlAfterCloseValue) {
      window.location.href = this.redirectToUrlAfterCloseValue;
    }

    this.element.close();
  }
}
