import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";
import ModalController from "../../modal/controller";

@TypedController
export default class extends Controller {
  declare deleteModalController: ModalController;

  @Target readonly deleteFormTarget: HTMLFormElement;
  @Target readonly spinnerTarget: HTMLElement;
  @Target readonly submitButtonTarget: HTMLButtonElement;

  connect() {
    const deleteModalElement = document.getElementById("delete-account-modal");
    if (!deleteModalElement) {
      throw new Error("Delete account modal not found");
    }

    this.deleteModalController = this.application.getControllerForElementAndIdentifier(deleteModalElement, "modal") as ModalController;

    this.deleteFormTarget.addEventListener("submit", this.deleteAccount.bind(this));
  }

  async deleteAccount(event: Event) {
    event.preventDefault();
    this.spinnerTarget.classList.remove("tw-hidden");
    this.submitButtonTarget.disabled = true;

    const response = await fetch(this.deleteFormTarget.action, {
      method: this.deleteFormTarget.method,
      body: new FormData(this.deleteFormTarget),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    if (response.ok) {
      this.deleteModalController.closeModal();
      this.showSuccessModal();
    } else {
      window.location.reload();
    }
  }

  showSuccessModal() {
    const successModalElement = document.getElementById("delete-account-success-modal");
    if (!successModalElement) {
      throw new Error("Delete account success modal not found");
    }

    const successModalController = this.application.getControllerForElementAndIdentifier(successModalElement, "modal") as ModalController;
    successModalController.openModal();
  }
}
