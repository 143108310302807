import { Targets, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Targets
  declare readonly navigationItemTargets: HTMLAnchorElement[];
  static values = {
    linkIds: { type: Array, default: [] },
  };
  linkIdsValue: any;

  connect() {
    this.setupCenterMobileNavigationAfterClick();

    // Highlight proper navigation item on page load
    this.highlightProperNavigationItem();

    // Highlight proper navigation item on each scroll
    document.querySelector("#main-content")!.addEventListener("scroll", this.highlightProperNavigationItem);
  }

  // after clicking on the mobile navigation the clicked item should be centered in the viewport
  private setupCenterMobileNavigationAfterClick() {
    this.navigationItemTargets.forEach((link) => {
      link.addEventListener("click", (e) => {
        const listItem = link.closest("li");
        if (listItem) {
          listItem.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          });
        }
      });
    });
  }

  // will calculate the current visible section and highlight the proper navigation item
  private highlightProperNavigationItem = () => {
    const sections = this.linkIdsValue.map((linkId) => document.getElementById(linkId)).filter((section): section is HTMLElement => section !== null);

    // Find current visible section
    const currentSections = sections.filter((section) => {
      const rect = section.getBoundingClientRect();
      return rect.top < window.innerHeight / 2;
    });

    let currentSection = currentSections.at(-1);

    // If no section is found and near the bottom, use last section
    if (!currentSection) {
      currentSection = sections[0];
    }

    if (currentSection) {
      this.navigationItemTargets.forEach((item) => {
        const fullUrl = new URL(window.location.href);
        fullUrl.hash = item.getAttribute("href")!;

        if (item.getAttribute("href") === `#${currentSection?.id}`) {
          item.classList.add("tw-font-bold");
          item.dataset.ui = "active";

          if (window.location.href !== fullUrl.href) {
            window.history.replaceState({}, "", fullUrl);

            // Add horizontal scroll for active navigation item
            const listItem = item.closest("li");
            if (listItem) {
              listItem.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }
          }
        } else {
          item.classList.remove("tw-font-bold");
          item.dataset.ui = "";
        }
      });
    }
  };

  disconnect() {
    window.removeEventListener("scroll", this.highlightProperNavigationItem);
  }
}
