import { Controller } from "@hotwired/stimulus";
import { TypedController, Value } from "@vytant/stimulus-decorators";

@TypedController
export default class extends Controller {
  @Value(String) textValue: string;

  copy() {
    navigator.clipboard.writeText(this.textValue);
  }
}
