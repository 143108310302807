import application from "./application";
import { registerControllers } from "stimulus-vite-helpers";

const allControllers = import.meta.globEager("../controllers/*_controller.@(ts|js)");
const allComponentControllers = import.meta.globEager("../../components/**/(*_)?controller.@(ts|js)");

import { componentControllers, controllers } from "./application";

const filteredControllers = Object.fromEntries(
  Object.entries(allControllers).filter(([key]) => !(key in controllers))
);

const filteredComponentControllers = Object.fromEntries(
  Object.entries(allComponentControllers).filter(([key]) => !(key in componentControllers))
);

registerControllers(application, filteredControllers);
registerControllers(application, filteredComponentControllers);
