import { Controller } from "@hotwired/stimulus";
import { TypedController, Value } from "@vytant/stimulus-decorators";

@TypedController
export default class extends Controller<HTMLFormElement> {
  @Value(String) textValue!: string;

  connect() {
    this.element.addEventListener("submit", (event) => {
      if (event.submitter?.dataset.skipConfirm !== "true") {
        if (!confirm(this.textValue || "Are you sure?")) {
          event.preventDefault();
          return;
        }
      }

      this.element.form?.requestSubmit();
    });
  }
}
