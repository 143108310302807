import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller<HTMLDetailsElement> {
  connect() {
    useClickOutside(this, { element: this.element });
  }

  clickOutside(event: Event) {
    this.element.open = false;
  }
}
